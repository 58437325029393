export const useMailchimp = () => {
  async function callApi(method: String, params = {}) {
    const config = useRuntimeConfig();

    try {
      const { data, error } = await useFetch(
        () => `${config.public.zweikernApiUrl}/newsletter/subscribe`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            method,
            ...params,
          }),
        },
      );

      if (error.value) {
        throw new Error(error.value.message || "Network response was not ok");
      }

      return { data: data.value?.data, error };
    } catch (error) {
      return { error };
    }
  }

  async function callPing() {
    return await callApi("ping.get");
  }

  async function subscribeUser(email) {
    return await callApi("lists.addListMember", { email });
  }

  async function getListMembersInfo() {
    return await callApi("lists.getListMembersInfo");
  }

  return {
    callPing,
    subscribeUser,
    getListMembersInfo,
  };
};
