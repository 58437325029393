<template>
  <VueConfettiExplosion
    :colors="[
      'var(--gradient-1)',
      'var(--gradient-2)',
      'var(--gradient-3)',
      'var(--gradient-4)',
      '#ffffff',
    ]"
  />
</template>
